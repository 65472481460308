<template>
  <div>
    <template v-if="isLoadingLocations">
      <div class="d-flex justify-content-center mt-2 mb-2">
        <b-spinner />
      </div>
    </template>
    <template v-else-if="activeLocation != undefined">
      <location-picker-bar
        :includeCreateInfoCard="false"
        :locations="locations"
        v-model="activeLocation"
      >
        <template #right>
          <button class="btn float-right dark-btn" @click="$router.go(-1)">
            {{ T("Web.Generic.Back", "Back") }}
          </button>
        </template>
      </location-picker-bar>
      <div
        v-if="isLoadingInfoCards"
        class="d-flex justify-content-center mt-2 mb-2"
      >
        <b-spinner />
      </div>

      <archived-info-card-list-table
        v-else
        :location="activeLocation"
        :info-cards="infoCards"
        @unArchive="unArchive"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BSpinner,
  BCard,
  BButton,
  BRow,
  BCol,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ArchivedInfoCardListTable from "@/components/tables/infoCards/ArchivedInfoCardListTable.vue";
import LocationPickerBar from "@/components/LocationPickerBar.vue";
import ResourceService from '@/services/base/resource.service';

export default {
  components: {
    BSpinner,
    BCard,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    ArchivedInfoCardListTable,
    LocationPickerBar,
  },
  async created() {
    await this.fetchLocations();
    this.activeLocation = this.locations[0];
  },
  data() {
    return {
      activeLocation: undefined,
    };
  },
  computed: {
    ...mapGetters({
      locations: "locationsOld/list",
      isLoadingLocations: "locationsOld/isLoading",
      infoCards: "infoCards/archivedList",
      isLoadingInfoCards: "infoCards/isLoading",
    }),
    hasNoLocations() {
      return !(this.locations && this.locations.length > 0);
    },
  },
  methods: {
    ...mapActions({
      fetchLocations: "locationsOld/fetchList",
      fetchInfoCards: "infoCards/fetchArchived",
    }),
    async openLocationCreateModal() {
      await this.$refs.locationCreateModal.open();
    },
    async unArchive(id) {
      const service = new ResourceService("background-data/infocards");
      await service.post(`${id}/unarchive`);
      this.fetchInfoCards({ params: { locationId: this.activeLocation.id } });
    },
  },
  watch: {
    activeLocation(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.fetchInfoCards({ params: { locationId: this.activeLocation.id } });
    },
  },
};
</script>