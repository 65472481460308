<template>
  <dragablemodal
    :name="'editInfoCardModal'"
    ref="dragablemodal"
    :height="'auto'"
    :width="900"
  >
    <confirm-modal ref="confirmModal" />
    <header>{{ $t("EditInfoCardModal.Title") }}</header>

    <div class="modals-content">
      <transition name="fade" mode="out-in">
        <div class="d-flex justify-content-center mt-2 mb-2" v-if="loading">
          <b-spinner />
        </div>
        <container-info-card-update-form
          :location="location"
          :infoCard="infoCard"
          v-else-if="type === 'ContainerInfoCard'"
          @can-submit-status-changed="(state) => (canSubmit = state)"
          ref="infoCardForm"
        />
        <account-info-card-create-form
          :location="location"
          :infoCard="infoCard"
          :resource-id="infoCard.id"
          v-else-if="type === 'AccountInfoCard'"
          @can-submit-status-changed="(state) => (canSubmit = state)"
          ref="infoCardForm"
        />
      </transition>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="!canSubmit || submitting"
          @click="handleSubmit"
        >
          {{ submitting ? $t("EditInfoCardModal.Saving") : $t("Generic.Save") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleCancel"
        >
          {{ $t("Generic.Cancel") }}
        </b-button>
        <b-button variant="outline-danger" class="mr-2" @click="handleDelete">
          {{ $t("EditInfoCardModal.DeleteBtn") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import ContainerInfoCardUpdateForm from "@/components/forms/infoCards/ContainerInfoCardUpdateForm.vue";
import AccountInfoCardCreateForm from "@/components/forms/infoCards/AccountInfoCardCreateForm.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { mapActions, mapGetters } from "vuex";
import ConfirmModal from "../ConfirmModal.vue";
import dragablemodal from "@/components/layout/dragablemodal.vue";
export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BButton,
    BSpinner,
    ContainerInfoCardUpdateForm,
    AccountInfoCardCreateForm,
    FeatherIcon,
    ConfirmModal,
    dragablemodal,
  },
  data() {
    return {
      infoCard: undefined,
      resolvePromise: undefined,
      rejectPromise: undefined,
      type: undefined,
      location: undefined,
      canSubmit: false,
      submitting: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: "infoCards/isLoading",
      getInfoCard: "infoCards/byId",
    }),
  },
  methods: {
    ...mapActions({
      fetchInfoCard: "infoCards/fetchSingleOfType",
      removeInfocard: "infoCards/removeOfType",
      fetchInfoCards: "infoCards/fetchList",
    }),
    setType(type) {
      this.type = type;
    },
    handleModalHidden() {
      this.$refs.dragablemodal.hide();

      this.type = undefined;
      this.infoCard = undefined;
    },
    async handleSubmit() {
      this.submitting = true;
      try {
        const form = this.$refs.infoCardForm;
        await form.submit();
        this.handleModalHidden();
        return this.resolvePromise(true);
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
    async handleDelete() {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.$t("Generic.ArchiveInfoCardConfirm")
        ))
      )
        return;
      await this.removeInfocard({ id: this.infoCard.id, type: this.type });
      this.fetchInfoCards({ params: { locationId: this.infoCard.locationId } });
      this.resolvePromise(false);
      this.handleModalHidden();
    },
    async open(id, type) {
      this.infoCard = null;
      this.$refs.dragablemodal.show();

      await this.fetchInfoCard({ id: id, type: type });
      this.infoCard = this.getInfoCard(id);
      this.type = this.infoCard.type;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-card-type-picker {
  display: flex;
  justify-content: space-evenly;
  .type-option {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 17px;
      margin-top: 10px;
    }
  }
}
</style>