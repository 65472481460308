<template>
  <div class="wrap">
    <b-card no-body>
      <edit-info-card-modal ref="editInfoCardModal" />
      <div class="header">
        <b-card-title>{{ T("Web.ArchivedInfocardsPage.Title", "Archived Infocards") }}</b-card-title>
      </div>
      <div class="body">
        <b-table
          show-empty
          :emptyText="T('Web.Generic.TableEmpty', 'No items to show')"
          responsive
          :fields="tableContent.fields"
          :items="tableContent.items"
        >
          <template #cell(name)="data">
            <b-avatar
              v-if="data.item.coverPath"
              :src="baseUrl + '/images/' + data.item.coverPath"
              class="mr-1"
            />
            <b-avatar
              v-else
              src="@/assets/images/container-no-image.jpg"
              class="mr-1"
            />
            <strong>{{ data.item.name }}</strong>
          </template>
          <template #cell(type)="data">
            <b-badge variant="light-primary">
              {{ getTypeNameFromType(data.item.type) }}
            </b-badge>
          </template>
          <template #cell(deleteDate)="data">
            {{ format_datetime(data.item.deleteDate) }}
          </template>
          <template #cell(actions)="data">
            <b-button variant="flat-secondary" class="float-right" size="sm" @click="$emit('unArchive',data.item.id)">{{T('Web.Generic.Unarchive', 'Unarchive')}}</b-button>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BRow,
  BCardTitle,
  BTable,
  BAvatar,
  BFormCheckbox,
  BCol,
  BButton,
  BBadge,
} from "bootstrap-vue";
import CreateInfoCardModal from "@/components/modals/infoCards/CreateInfoCardModal.vue";
import EditInfoCardModal from "@/components/modals/infoCards/EditInfoCardModal.vue";
import Ripple from "vue-ripple-directive";
import ResourceService from '@/services/base/resource.service';

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_APIURL,
    };
  },
  props: {
    location: {
      required: true,
    },
    infoCards: {
      required: true,
    },
  },
  components: {
    BCard,
    BSpinner,
    BRow,
    BCardTitle,
    BTable,
    BAvatar,
    BFormCheckbox,
    BCol,
    BButton,
    BBadge,
    CreateInfoCardModal,
    EditInfoCardModal,
  },
  directives: {
    Ripple,
  },
  mounted() {},
  computed: {
    tableContent() {
      return {
        emptyText: this.T('Web.Generic.TableEmpty', 'No items to show'),
        fields: [
          {
            key: "name",
            label: this.T('Web.Generic.Name', 'Name'),
            sortable: true,
          },
          {
            key: "Type",
            label: this.T('Web.Generic.Type', 'Type'),
            sortable: true,
          },
          {
            key: "deleteDate",
            label: this.T('Web.Generic.DeletedAt', 'Deleted at'),
            sortable: true,
          },
             {
            key: "actions",
            label: "",
            sortable: false,
          },
        ],
        items: this.infoCards,
      };
    },
  },
  methods: {
    getTypeNameFromType(type) {
      switch (type) {
        case "ContainerInfoCard":
          return this.T('Web.Generic.Infocards.Types.Container', 'Container/Fraction');
        case "AccountInfoCard":
          return this.T('Web.Generic.Infocards.Types.Account', 'Account');
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.wrap {
  .card {
    .header {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      .card-title {
        margin-bottom: 0px;
      }

      .collapse-toggle {
        cursor: pointer;
      }
    }
  }
}
</style>